import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@base/environments/environment';
import { QrFilter } from '@app/core/models/qr-filter';
import { Observable, first } from 'rxjs';
import queryString from 'query-string';
import { Auth } from 'aws-amplify';
import { IQrResponse } from '@app/core/models/IQrResponse';
export abstract class HttpService {
  constructor(
    protected http: HttpClient,
    protected _url: string
  ) {}

  setJwtToken(): Promise<HttpHeaders> {
    return new Promise(resolve => {
      Auth.currentSession()
        .then(session => {
          const token = session.getAccessToken().getJwtToken();
          resolve(
            new HttpHeaders({
              Authorization: `Bearer ${token}`,
            })
          );
        })
        .catch(() => resolve(new HttpHeaders()));
    });
  }

  ////
  findAll(
    qrFilter: QrFilter = new QrFilter(),
    headers?: HttpHeaders
  ): Promise<IQrResponse<any>> {
    return new Promise((resolve, reject) => {
      if (headers) {
        this.findAllObservable(qrFilter, headers)
          .pipe(first())
          .subscribe(
            (data: IQrResponse<any>) => {
              resolve(data);
            },
            error => {
              reject(error);
            }
          );
      } else {
        this.findAllObservable(qrFilter)
          .pipe(first())
          .subscribe(
            (data: IQrResponse<any>) => {
              resolve(data);
            },
            error => {
              reject(error);
            }
          );
      }
    });
  }

  findAllObservable(
    qrFilter: QrFilter = new QrFilter(),
    headers?: HttpHeaders
  ): Observable<any> {
    const queryString = qrFilter.toQueryStringBe();

    return this.http.get(
      environment.apiUrl + this._url + 'findAll?' + queryString,
      { headers: headers }
    );
  }

  ////
  get(id: string): Promise<IQrResponse<any>> {
    return new Promise((resolve, reject) => {
      this.getObservable(id)
        .pipe(first())
        .subscribe(
          (data: IQrResponse<any>) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  getObservable(id: string): Observable<any> {
    return this.http.get(environment.apiUrl + this._url + id);
  }

  findById(id: string): Promise<IQrResponse<any>> {
    return new Promise((resolve, reject) => {
      this.findByIdObservable(id)
        .pipe(first())
        .subscribe(
          (data: IQrResponse<any>) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  findByIdObservable(id: string): Observable<any> {
    return this.http.get(
      environment.apiUrl + this._url + 'findByinternalId/' + id
    );
  }

  ////
  add(body: Record<string, any>): Promise<IQrResponse<any>> {
    return new Promise((resolve, reject) => {
      this.addObservable(body)
        .pipe(first())
        .subscribe(
          (data: IQrResponse<any>) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  addObservable(body: Record<string, any>): Observable<any> {
    return this.http.post(environment.apiUrl + this._url + 'add/', body);
  }

  ////
  addGet(body: Record<string, any>): Promise<IQrResponse<any>> {
    return new Promise((resolve, reject) => {
      this.addGetObservable(body)
        .pipe(first())
        .subscribe(
          (data: IQrResponse<any>) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  addGetObservable(body: Record<string, any>): Observable<any> {
    const response: string = queryString.stringify(body, {
      skipEmptyString: true,
      skipNull: true,
    });
    return this.http.get(environment.apiUrl + this._url + 'add/?' + response);
  }

  ////
  update(body: Record<string, any>): Promise<IQrResponse<any>> {
    return new Promise((resolve, reject) => {
      this.updateObservable(body)
        .pipe(first())
        .subscribe(
          (data: IQrResponse<any>) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  updateObservable(body: Record<string, any>): Observable<any> {
    return this.http.put(environment.apiUrl + this._url + 'update/', body);
  }

  ////
  delete(id: string): Promise<IQrResponse<any>> {
    return new Promise((resolve, reject) => {
      this.deleteObservable(id)
        .pipe(first())
        .subscribe(
          (data: IQrResponse<any>) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  deleteObservable(id: string): Observable<any> {
    return this.http.delete(environment.apiUrl + this._url + 'delete/' + id);
  }
}
